<template>
    <footer class="footer">
        <img src="../assets/logo.png" class="fotlogo">
      <div class="footer-content">
        <div class="cards">
          <div class="card">
            <p>{{$t('dayofwork')}}</p>
            <p>8<sup>00</sup>-17<sup>00</sup></p>
            <p>{{$t('saturday')}}</p>
            <p>10<sup>00</sup>-15<sup>00</sup></p>
          </div>
          <div class="card">

            <p><a href="mailto:info@masterprojekt.eu">info@masterprojekt.eu</a></p>
            <p><a href="tel:+38344217368">+383 (0) 44-217-368</a></p>
            <p><a href="tel:+38344336343">+383 (0) 44-336-343</a></p>
            <p><a href="tel:+38344412392">+383 (0) 44-412-392</a></p>
          </div>

          <div class="card">
            <p>Kosovo, Prizren, Rruga Tirana 2</p>
          </div>
          <div class="card">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2955.2271241170065!2d20.72483727659703!3d42.209590144593776!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1353950f3bb18ee7%3A0xe7f5e42eb4c525bd!2sMaster%20Projekt!5e0!3m2!1shr!2shr!4v1729176887499!5m2!1shr!2shr"
              width="100%"
              height="100%"
              style="border:0;"
              allowfullscreen=""
              loading="lazy"
            ></iframe>
          </div>
        </div>
      </div>
      <div class="footer-bottom">
        <hr />
        <p>© 2016-2025 Master Projekt Group. {{$t('allrightreserved')}}</p>
      </div>
    </footer>
  </template>
  
  <script>
  export default {
    name: 'Footer'
  };
  </script>
  
  <style scoped>
  .footer {
    padding: 20px;
    background-color: #1a1a1a; /*#080404*/
    color: white;
    text-align: center; /* Centriranje footer-a kao celokupne */
  }
  
  .footer-content {
    display: flex;
    flex-direction: column;
  }
  
.fotlogo{
    display: none;
}
  
  p{
    color: rgb(235, 235, 235);
  }
  .cards {
    display: flex;
    justify-content: space-between; /* Razmak između kartica */
    margin-bottom: 20px;
  }
  
  .card {
    background-color: #313131;
    border-radius: 8px;
    padding: 15px;
    margin: 0 10px; /* Razmak između kartica */
    flex: 1; /* Raspoređivanje kartica */
    min-width: 150px; /* Minimalna širina kartice */
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  }
  
  .card-title {
    margin-bottom: 10px;
    color: rgb(235, 235, 235);
  }
  
  a {
    color: rgb(235, 235, 235); /* Boja linkova */
    text-decoration: none;
  }
  
  a:hover {
    text-decoration: underline;
  }
  
  .footer-bottom {
    text-align: center; /* Centriranje teksta u donjem delu */
    font-size: 15px;
    margin-top: 20px;
  }
  
  .footer-bottom hr {
    border: 1px solid rgb(235, 235, 235);
    margin: 10px 0;
  }
  
  @media (max-width: 768px) {
    .fotlogo{
    display: inline;
    width: 500px;
}
    .cards {
      flex-direction: column; /* Kartice će se poređati vertikalno na manjim ekranima */
      align-items: center; /* Centriranje kartica */
    }
  
    .card {
      margin-bottom: 15px; /* Razmak između kartica */
      width: 100%; /* Kartice će zauzeti punu širinu */
    }
  }
  </style>
  